import { render, staticRenderFns } from "./StatsComponent.vue?vue&type=template&id=0ba05c88&scoped=true"
import script from "./StatsComponent.vue?vue&type=script&lang=js"
export * from "./StatsComponent.vue?vue&type=script&lang=js"
import style0 from "./StatsComponent.vue?vue&type=style&index=0&id=0ba05c88&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../edwinariza.web/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ba05c88",
  null
  
)

export default component.exports