<template>
  <section class="brief" :id="$props.id">
    <h2>{{ title }}</h2>
    <h3>{{ subtitle }}</h3>
    <h4 v-html="content"></h4>
    <ButtonComponent class="mt-3 is-bold" @click="$emit('open-modal-contact')" :text="contactText[$props.lang]" type="warning" />
  </section>
</template>
<script>
  import ButtonComponent from "@/components/Buttons/ButtonComponent.vue";

  export default {
    name: 'BriefComponent',
    components: {ButtonComponent},
    props:{
      title:{ type: String, default: ''},
      subtitle:{ type: String, default: ''},
      content:{ type: String, default: ''},
      id:{ type: String, default: ''},
      lang:{ type: String, default: 'en'},
      background: {
        type: String,
        default: '#fefefe'
      }
    },
    data(){
      return{
        contactText:{
          es: "<b>Contactar</b>",
          en: "<b>Contact Me</b>"
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
  .brief{
    background: v-bind(background);
    text-align: center;
    padding:3rem;
    /**border-radius: 1em;
    margin:1em;**/
    h2{
      font-size: 2em;
      color: #222222;
      font-weight: bold;
    }
    h3{
      font-size:1.6em;
      color: #fefefe;
      font-weight: bold;
    }
    h4{
      font-size:1.8rem;
      color:#333333;
      margin-right: 15%;
      margin-left: 15%;
    }
  }
</style>