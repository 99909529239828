import { render, staticRenderFns } from "./PostComponent.vue?vue&type=template&id=1f7ccc25&scoped=true"
import script from "./PostComponent.vue?vue&type=script&lang=js"
export * from "./PostComponent.vue?vue&type=script&lang=js"
import style0 from "./PostComponent.vue?vue&type=style&index=0&id=1f7ccc25&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f7ccc25",
  null
  
)

export default component.exports