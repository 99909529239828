import { render, staticRenderFns } from "./ProductsOptionsComponent.vue?vue&type=template&id=033753a9&scoped=true"
import script from "./ProductsOptionsComponent.vue?vue&type=script&lang=js"
export * from "./ProductsOptionsComponent.vue?vue&type=script&lang=js"
import style0 from "./ProductsOptionsComponent.vue?vue&type=style&index=0&id=033753a9&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../edwinariza.web/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "033753a9",
  null
  
)

export default component.exports