<template>
  <div id="certifications"  class="container is-rounded">
    <h2>{{$props.title[$props.lang]}}</h2>
    <p class="has-text-centered" style="font-size: 2em">
    <div style="max-width: 1280px;">
      <div class="columns mr-5 ml-5">
        <div class="column col-4 align-content-center" style="text-align: center;" >
          <img class="mt-6 img-fluid" style="max-width: 200px;" src="@/assets/certifications/PMI_PMP_logo1.png" />
        </div>
        <div class="column col-4 text-center" style="text-align: center;">
          <img class="img-fluid align-self-center" style="max-width: 200px;" src="@/assets/certifications/sa_badge_csd.webp" />
        </div>
        <div  class="column col-4 text-center" style="text-align: center;">
          <img class="img-fluid align-self-center" style="max-width: 200px;"  src="@/assets/certifications/sa_badge_csp_po.webp" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'CertificationsComponent',
    props:{
      title:{
        type: Object,
        default: () => ({ EN: 'My Certifications', ES: 'Mis Certificaciones' })
      },
      lang: {
        type: String,
        default: 'EN'
      }
    }
  }
</script>
<style scoped>
  .container{
    min-height: fit-content;
    max-height: fit-content;
    padding: 1em;
  }
  h2{
    margin-top: 4rem;
    color: #222222;
    margin-bottom: 4rem;
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
  }
  p{
    font-size: medium;
  }

</style>