<template>
  <section class="timeline">
    <div class="title" v-html="$props.title"></div>
    <div class="subtitle" v-html="$props.subtitle"></div>
    <div v-for="step in steps" :key="step.id" class="mt-5 p-5  border-0" id="stages">
      <!-- ELEMENTOS DE LA DERECHA -->
      <div class="columns item_texto_1" v-if="step.side === 'right'" >
        <div class="column">
            <h2>{{step.title}}</h2>
            <h3>{{step.description}}</h3>
        </div>
        <div class="column">
          <img :src="require('@/assets/formulator/'+step.icon)" class="img-fluid" />
        </div>
      </div>

      <!-- ELEMENTOS DE LA IZQUIERDA-->
      <div class="columns item_texto_2" v-else >
        <div class="column">
          <img :src="require('@/assets/formulator/'+step.icon)" class="img-fluid" />
        </div>
        <div class="column ">
          <h2>{{step.title}}</h2>
          <h3>{{step.description}}</h3>
        </div>
      </div>
    </div>

  </section>
</template>
<script>
  export default {
    name:'ActivityTimeLine',
    props:{
      title:{ type: String, default: ''},
      subtitle:{ type: String, default: ''},
      background: {
        type: String,
        default: '#fefefe'
      }
    },
    data(){
      return{
        steps:[
                {
                  id:1,
                  title:"Identificación de necesidades.",
                  icon:"formulator_1.png",
                  description:"Identificar con claridad la motivaciones que dan cabida al proyecto en el contexto en el que se desarrolla como punto de partida para la formulación.",
                  side:"right"
                },
                {
                  id:2,
                  title:"Identificación de problemas.",
                  icon:"formulator_2.png",
                  description:"Generar un árbol de problemas con causas, efectos y acciones que den claridad de lo que se busca solucionar realmente, priorizando los problemas e identificando a los interesados.",
                  side:"left"
                },
                {
                  id:3,
                  title:"Investigación asociada.",
                  icon:"formulator_3.png",
                  description:"Investigar el estado del arte, proyectos similares, avances de la empresa, comprender lo que el proyecto involucra y el impacto de sus resultados.",
                  side:"right"
                },
                {
                  id:4,
                  title:"Soluciones propuestas.",
                  icon:"formulator_4.png",
                  description:"Proponer versiones iterativas de soluciones, que poco a poco se consolidan como un proyecto preliminar que puede ser evaluado y ajustado hasta conseguir una versión madura.",
                  side:"left"
                },
                {
                  id:5,
                  title:"Evaluaciones Técnica, Económica y Financiera.",
                  icon:"formulator_5.png",
                  description:"Realizar ejercicios de evaluación técnica,  económica y financiera que generen nuevas ideas y propongan afinamientos que incrementen la calidad del proyecto.",
                  side:"right"
                },
                {
                  id:6,
                  title:"Generación.",
                  icon:"formulator_6.png",
                  description:"Generar un plan de proyecto alineado a todos los pasos anteriores, con caridad en su objetivo, identificación clara de los interesados, cubrimiento técnico y demás detalles pertinentes.",
                  side:"left"
                }
              ]
      }
    }
  }
</script>

<style lang="scss" scoped>
  .timeline{
    background: v-bind(background);
    .title{
      font-size: 2em;
      color:#222222;
      font-weight: bold;
      text-align: center;
      padding-top:2em;
    }
    .subtitle{
      font-size: 1.5em;
      color:#333333;
      text-align: center;
    }
    h2{
      color:#7d8aa7;;
      font-size:2.5em;
    }
    h3{
      color:#fefefe;
      font-size:1.8em;
      font-weight:normal;
      padding-left:2em;
      padding-right:2em;
    }
    /** RIGHT SIDE **/
    .item_texto_1{
      text-align: center;
      background:transparent;
      border-radius: 2em;
      padding:2em;
      h2{
        color:#222222;
        margin-top: 3em;
      }
      h3{
        color:#333333;
        padding: 0;
      }
    }
    /** LEFT SIDE **/
    .item_texto_2{
      text-align: center;
      padding:10px;
      h2{
        color:#222222;
        margin-top: 3em;
      }
      h3{
        color:#333333;
        padding: 0;
      }
    }
  }
</style>
