<template>
  <section class="hero pt-2 pb-5" :style="'background-color:'+background">
    <div class="hero-body mb-1" >
      <div class="columns">
        <div class="column col-7">
          <p class="title" :style="'color:'+color">
            {{ title }}
          </p>
          <p class="subtitle" :style="'color:'+color"  v-html="$props.subtitle"></p>
          <ButtonComponent :text="contactText[$props.lang]"
                           style="font-size:1.3rem;"
                           type="primary" id="button_contact_me"
                           @click="$emit('open-modal-contact')" />
          <p class="pt-2" v-html="$props.note"></p>
        </div>
        <div class="column col-5 is-hidden-mobile mt-5">
          <img class="mt-2" :src="image" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
    import ButtonComponent from "@/components/Buttons/ButtonComponent.vue";
    export default {
        name: 'BannerComponent',
        components: {
          ButtonComponent
        },
        props: {
          title: { type: String, default: '' },
          subtitle: { type: String, default: '' },
          image: { type: String, default: '' },
          showButtons: { type: Boolean, default: true },
          background: { type: String, default: '#fefefe' },
          color: { type: String, default: '#333333' },
          lang:{ type: String, default: 'EN'},
          note: { type: String, default: '' }
        },
        data(){
          return{
            contactText:{
              ES: "<b>Contactar</b>",
              EN: "<b>Contact Me</b>"
            }
          }
        },
        setup(props) {
          console.log(props.title)
          console.log(props.subtitle)
          console.log(props.image)
        },
    };
</script>

<style lang="scss" scoped>
@import "../../assets/main.scss";
  .hero{
    height: 75vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .hero-body{
    max-width: $page_content_width;
  }
  .title{
    font-size: 3.8rem;
    margin-top: 11vh;
    font-weight: bold;
  }
  .subtitle{
    font-size: 2em;
    margin-bottom: 4vh;
  }

  @media only screen and (max-width: 480px){
    .hero{
      min-height: 80vh;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .hero-body{
      max-width: $page_content_width;
      padding-top: 6em;
    }
    .title{
      font-size: 3em;
      margin-top: 3vh;
      padding:2vh;
    }
    .subtitle{
      font-size: 1.5em;
      width: 90vw;
      margin-left:2vh;
    }
  }
</style>