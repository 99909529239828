<template>
  <div class="home_view">
    <BannerComponent
        :title="banner[lang].title"
        :subtitle="banner[lang].subtitle"
        :image="require('@/assets/consultant_2.svg')"
        showButtons="true"
        background="#fefefe"
        color="#222222"
        style="z-index:200;"
        :buttonName="banner[lang].button"
        @open-modal-contact="$emit('open-modal-contact')"
    />
    <StatsComponent style="margin-top:-1px" background="#FFC107" :stats="stats[lang]" />
    <NavLanding class="is-hidden-touch" background="#222222" :menus="sections[lang]" />
    <IamComponent :lang="lang" />
    <FilesComponent title="Formats and How-to's"
                    subtitle="Project manager's formats for different situations and needs."
                    :items="this.files"/>
    <StepsComponent />
    <PostsComponent />
    <CertificationsComponent />
    <ExperienceComponent />
    <section class="mskills">
      <div class="mskills-content">
        <div class="columns m-0 p-0">
          <div class="column">
            <SkillsComponent />
          </div>
          <div class="column">
            <TipComponent />
          </div>
        </div>
      </div>
    </section>
    <!-- SERVICES-->
    <FaqComponent />
    <ProductsOptionsComponent id="pricing" />
    <LangButtonComponent :lang="$props.lang" :alternatives="alternatives" />
  </div>
</template>
<script>
import BannerComponent from "../../../edwinariza.ui/components/Banner/BannerComponent.vue";
import StatsComponent from "../../../edwinariza.ui/components/Stats/StatsComponent.vue";
import StepsComponent from "@/components/Steps/StepsComponent.vue";
import PostsComponent from "../../../edwinariza.ui/components/Posts/PostsComponent.vue";
import FaqComponent from "@/components/Faq/FaqComponent.vue";
import SkillsComponent from "@/components/Skills/SkillsComponent.vue";
import TipComponent from "@/components/Tips/TipComponent.vue";
import ProductsOptionsComponent from "@/components/Paypal/ProductsOptionsComponent.vue";
import CertificationsComponent from "../../../edwinariza.ui/components/Content/CertificationsComponent.vue";
import IamComponent from '../../../edwinariza.ui/components/Intro/IamComponent.vue';
import NavLanding from "../../../edwinariza.ui/components/NavBar/NavLanding.vue";
import ExperienceComponent from "@/components/Intro/ExperienceComponent.vue";
import FilesComponent from "../../../edwinariza.ui/components/Content/FilesComponent.vue";
import LangButtonComponent from "../../../edwinariza.ui/components/Buttons/LangButtonComponent.vue";

export default {
  name: 'HomeView',
  components:{
    LangButtonComponent,
    FilesComponent,
    StatsComponent,
    ExperienceComponent,
    NavLanding,
    CertificationsComponent,
    ProductsOptionsComponent,
    SkillsComponent,
    FaqComponent,
    PostsComponent,
    StepsComponent,
    BannerComponent,
    TipComponent,
    IamComponent
  },
  props: {
    lang: {
      type: String,
      default: 'EN'
    },
    title: {
      type: String,
      default: 'Edwin Ariza - IT Expert'
    },
    description: {
      type: String,
      default: 'Innovation and technology consultant.'
    }
  },
  data(){
    return {
      banner: {
        ES: {
          title: 'Proyectos para Innovación y tecnología.',
          subtitle: 'Soy <b>edwin ariza</b>, ingeniero ayudando a planificar y transformar ideas en soluciones tecnológicas simples y geniales.',
          note: 'Empieza ahora y aumenta tus oportunidades de negocio y mejores prácticas.',
          button: 'Contáctame'
        },
        EN: {
          title: 'Projects for Innovation and technology.',
          subtitle: 'I\'m <b>edwin ariza</b>, engineer helping to plan and transform ideas into simple and cool tech solutions.',
          note: 'Start now and increase your business opportunities and best practices.',
          button: 'Contact me'
        }
      },
      alternatives: [
        {id: 'ES', name: 'Español', link: '/es/'},
        {id: 'EN', name: 'English', link: '/'}
      ],
      stats: {
        ES: [
          {
            value: "15+",
            description: "Años de experiencia"
          },
          {
            value: "50+",
            description: "Proyectos"
          },
          {
            value: "2",
            description: "Países"
          },
          {
            value: "50+",
            description: "Clientes"
          }
        ],
        EN: [
          {
            value: "15+",
            description: "Years of experience"
          },
          {
            value: "50+",
            description: "Projects"
          },
          {
            value: "2",
            description: "Countries"
          },
          {
            value: "50+",
            description: "Clients"
          }
        ]
      },
      sections: {
        ES: [
            { id:1, name: 'acerca', url: '#iam'},
            { id:2, name: 'metodología', url: '#ideatobis'},
            { id:4, name: 'publicaciones', url: '#posts'},
            { id:5, name: 'certificaciones', url: '#certifications'},
            { id:6, name: 'servicios', url: '#pricing'}
        ],
        EN: [
            { id:1, name: 'about', url: '#iam'},
            { id:2, name: 'methodology', url: '#ideatobis'},
            { id:4, name: 'posts', url: '#posts'},
            { id:5, name: 'certifications', url: '#certifications'},
            { id:6, name: 'services', url: '#pricing'}
        ]
      },
      files: [
        {
          id: 2,
          name: 'Project Charter.',
          description:'Define requirements clearly with the correct and required information.',
          url: 'https://docs.google.com/document/d/e/2PACX-1vSQvY9vu1YQKPCnmm11YK46pQjBofRSz5JH2x8M5aPeBJ_BZVoO18cx6BOfTDd2zLyCtsRuHvJaD1uo/pub',
          image: 'icons/document.svg',
          type: 'format'
        }
      ]
    }
  },
  beforeMount() {
    this.$emit('updateMeta', this.title, this.description, this.lang);
  }
}
</script>
<style lang="scss">
  @import "../assets/main.scss";
  *{
    font-family:"Quicksand", sans-serif;
  }
  .home_view{

  }
  .mskills{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .mskills-content{
      max-width: $page_content_width;
      width: 100%;
    }
  }
</style>