<template>
  <section>
    <b-modal v-model="$props.visible" :width="840" scroll="keep" @close="$emit('close')" destroy-on-hide="false">
        <div class="card">
            <div class="card-image p-3" style="background-color: #fefefe;border-bottom: 2px solid #222222;">
                <div class="media">
                    <div class="media-left">
                        <figure class="image is-48x48">
                            <img src="https://edwinariza.com/static/avatars/10.png" alt="Image">
                        </figure>
                    </div>
                    <div class="media-content">
                        <p class="title is-4">Edwin Ariza</p>
                        <p class="subtitle is-6">@edwin_ariza</p>
                    </div>
                </div>
            </div>
            <div class="card-content">
                <div class="columns">
        <div class="column">
          <div><b>Option 1</b>: Send a request using my request and complaints platform:<br></div>
            <div class="pt-2 is-center">
              <a class="button is-info"  id='emailButton' @click="contactSuppmail">
                Register Request
              </a>
            </div>
          </div>
          <div class="column">
            <div><b>Option 2</b>: Chat with me using an <b>Instant Chat</b>:</div>
            <div class="pt-2 is-center">
              <a style="width: 100%" class="button is-success-passive mb-2" target="_blank" href="https://wa.me/13146681412">
                <img src="https://cdn.systemico.net/systemico/icons/icon-whatsapp.svg" width="24px" />
                &nbsp;WhatsApp
              </a>
              <a style="width: 100%"  class="button is-info-passive " target="_blank" href="https://m.me/edwinarizapro">
                <img src="https://cdn.systemico.net/systemico/icons/icon-messenger-blue.svg" width="24px" />
                &nbsp;Messenger
              </a>
            </div>
          </div>
          <div class="column">
            <div><b>Option 3</b>: Send me an email to:<br></div>
            <div class="pt-2 is-center">
              <a class="button is-success"  id='emailButton'
                 href="mailto:hello@edwinariza.com">
                hello@edwinariza.com
              </a>
            </div>
          </div>
        </div>
            </div>
        </div>
    </b-modal>
  </section>
</template>
<script>

  export default {
    name: "ModalContactComponent",
    props: ['visible'],
    setup(props) {
      console.log(props)
    },
    components: {
    },
    methods:{
      copyEmail() {
        alert("Hola")
        // Get the text field
        let copyText = document.getElementById("emailButton");
        // Select the text field
        let email = copyText.innerText;
         // Copy the text inside the text field
        navigator.clipboard.writeText(email);
      },
      contactSuppmail(){
        window.suppmail.$children[0].loadmodalcontact(this.dataContact, 'es');
        this.$emit('close');
      },
      closeModal(){

      }
    },
    data() {
      return {
        dataContact:
        {
          en: {
            tag: 'Feedback, complaints, suggestions or more.',
            title: 'Just send your request, i\'m here to help you',
            content: 'I\'m a project manager and IT expert with experience ready to help and share with you to improve your products or business.',
            image: 'https://edwinariza.com/static/headers/contact/contact-2.svg'
          },
          es: {
            tag: 'Feedback, complaints, suggestions or more.',
            title: 'Just send your request, i\'m here to help you',
            content: 'I\'m a project manager and IT expert with experience ' +
                'ready to help and share with you. We can work together to improve your products and business.',
            image: 'https://edwinariza.com/static/headers/contact/contact-2.svg'
          }
        }
      }
    }
  }
</script>