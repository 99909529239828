<template>
  <section class="posts" id="posts">
    <div class="post-content mt-5">
      <div class="ml-2">
        <h2 v-html="title[$props.lang]"></h2>
      </div>
      <div class="columns" >
        <div class="column is-one-quarter" v-for="item in items" v-bind:key="item.id">
          <PostComponent
              :title="item.title"
              :content="item.content"
              :date="item.date"
              :image="'https://edwinariza.com/'+item.image"
              :author="item.author"
              :url="item.url"
          />
        </div>
        <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="false"></b-loading>
      </div>
    </div>
  </section>
</template>

<script>
import PostComponent from "@/components/Posts/PostComponent.vue";
import axios from 'axios';
export default {
  name: "PostsComponent",
  components: {
    PostComponent
  },
  props:{
    lang:{
      type: String,
      default: 'EN'
    }
  },
  data:function () {
    return{
      isLoading:true,
      isFullPage:true,
      items:[],
      title: {
        EN: 'Posts, ideas, opinions and knowledge.',
        ES: 'Artículos, ideas, opiniones y conocimiento.'
      },
    }
  },
  methods: {
    popularPosts: function(){
      axios.get('https://edwinariza.com/posts/get_more').then(response => {
        this.items = response.data;
        this.isLoading = false;
      });
    },
  },
  mounted() {
    // CARGAMOS LOS ARTICULOS
    this.popularPosts()
  }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/main.scss";
  .posts{
    border-bottom: 2px solid #222222;
    background-color: #a2d9ff;
    border-top: 3px solid #222222;
    padding-bottom: 2em;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .post-content{
      max-width: $page_content_width;
      h2{
        font-size: 2.0em;
        color: #333333;
        font-weight: bold;
        text-align: center;
        margin-bottom: 1em;
      }

    }

  }

</style>