import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import PostsView from "@/views/PostsView.vue";
import PaymentsView from "@/views/PaymentsView.vue";
import PaymentsSuccessView from "@/views/PaymentsSuccessView.vue";
import ResearcherView from '@/views/ResearcherView.vue';
import Developer from '@/views/DeveloperView.vue'
import Posts from '@/views/PostsView.vue'
import ProjectManager from '@/views/ProjectManagerView.vue'
import Formulator from "@/views/FormulatorView.vue";
import Entrepreneur from "@/views/EntrepreneurView.vue";
/*** TIMEBOX **/
import TimeBoxView from '@/views/apps/TimeBoxView.vue'
import WebCriteriasView from '@/views/apps/WebCriteriasView.vue'
import FormulatorView from "@/views/FormulatorView.vue";

Vue.use(VueRouter)

const routes = [
  /** ENGLISH **/
  {
    path: '/',
    name: 'home',
    component: HomeView,
    props: {
      title: 'Edwin Ariza - IT and Innovation Project Formulator.',
      description: 'IT and Innovation Project Formulator.',
      lang: 'EN'
    }
  },
  {
    path: '/payments',
    name: 'payments',
    component: PaymentsView
  },
  {
    path: '/blog',
    name: 'blog',
    component: Posts
  },
  {
  path: '/researcher',
  name: 'researcher',
  component: ResearcherView
  },
  {
    path: '/developer',
    name: 'developer',
    component: Developer
  },
  {
    path: '/project_manager',
    name: 'project_manager',
    component: ProjectManager
  },
  {
    path: '/formulator',
    name: 'formulator',
    component: Formulator,
    props: {
      title: 'Edwin Ariza - IT and Innovation Project Formulator.',
      description: 'IT and Innovation Project Formulator.',
      lang: 'EN'
    }
  },
  {
    path: '/entrepreneur',
    name: 'entrepreneur',
    component: Entrepreneur
  },
  {
    path: '/payments/success',
    name: 'payments_success',
    component: PaymentsSuccessView
  },
  {
    path: '/toolkit/timebox',
    name: 'timebox',
    component: TimeBoxView
  },
  {
    path: '/toolkit/web_criterias',
    name: 'web_criterias',
    component: WebCriteriasView
  },
  /** ESPAÑOL **/
  {
    path: '/es',
    name: 'home',
    component: HomeView,
    props: {
      title: 'Edwin Ariza - Consultor de Innovación y Proyectos de Tecnología.',
      description: 'IT and Innovation Project Formulator.',
      lang: 'ES'
    }
  },
  {
    path: '/es/formulador_de_proyectos',
    name: 'formulador_de_proyectos',
    component: FormulatorView,
    props: {
      title: 'Edwin Ariza - Formulador de Proyectos de Innovación y Tecnología.',
      description: 'Formulador de Proyectos de Innovación y Tecnología.',
      lang: 'ES'
    }
  },
  {
    path: '/es/gerente_de_proyectos',
    name: 'gerente_de_proyectos',
    component: ProjectManager,
    props: {
      title: 'Edwin Ariza - Gerente de Proyectos de Innovación y Tecnología.',
      description: 'Gerente de Proyectos de Innovación y Tecnología para impulsar el cumplimiento de tus metas de negocio.',
      lang: 'ES'
    }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
  path: '/blog/:article',
  name: 'PostsView',
  component: PostsView
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
