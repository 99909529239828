<template>
  <section class="testimonials_component" style="">
    <h2 class="is-center">{{$props.title[$props.lang]}}</h2>
    <div style="width: 30%;margin:auto">
      <b-message title="Diego Julian Cardenas - somosbida.com" :closable="false" aria-close-label="Close message" type="is-warning">
        <p>Queríamos transitar varios caminos al tiempo y gracias a acciones semanales pudimos enfocarnos y seguir avanzando, un acierto total.</p>
      </b-message>
    </div>
  </section>
</template>
<script>
export default {
  name: 'TestimonialsComponet',
  props:{
    title:{
      type: Object,
      default: () => ({ EN: 'Testimonials', ES: 'Testimonios' })
    },
    lang: {
      type: String,
      default: 'EN'
    }
  }
}
</script>
<style scoped lang="scss">
  .testimonials_component{
    min-height: fit-content;
    max-height: fit-content;
    padding: 1rem;
    color: white;
    h2{
      margin-top: 4rem;
      color: #222222;
      margin-bottom: 4rem;
      font-size: 2.5rem;
      font-weight: bold;
      text-align: center;
    }
  }
</style>